import React from 'react'

import Typical from 'react-typical'


import icon from '../images/icon.png'
import iconText from '../images/iconText.png'
import linkedinMetaImage from '../images/linkindin-meta.png'

const companyDatas = {
  name: 'CoTraveling',
  icon: icon,
  iconText: iconText,
  description: <>Des séjours entre <Typical steps={['indépendants', 2000, 'freelances', 2000, 'entrepreneurs', 2000]} loop={Infinity} wrapper="span"/><br/>pour <span style={{color: '#ce4c89'}}>travailler</span>, se <span style={{color: '#feb813'}}>détendre</span> et <span style={{color: '#04b7ce'}}>échanger</span></>,
  instagram: 'https://www.instagram.com/_cotraveling/',
  facebook: 'https://www.facebook.com/CoTraveling-103700215143095',
  linkedin: 'https://www.linkedin.com/company/cotraveling',
  email: 'hello@cotraveling.io',
  address: '1-7 cours Valmy, 92800 Puteaux',
  url: 'cotraveling.io',
  linkedinMetaImage: linkedinMetaImage
}

export default companyDatas

//description: <span>10 jours inoubliables entre indépendants<br/>pour <span style={{color: '#ce4c89'}}>travailler</span>, se <span style={{color: '#feb813'}}>détendre</span> et <span style={{color: '#04b7ce'}}>échanger</span></span>,
